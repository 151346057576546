var head = document.getElementsByTagName('head')[0];

var script = document.createElement('script');
script.type = 'text/javascript';
script.id = 'hs-script-loader';
script.defer = true;
script.async = true;
script.src = '//js.hs-scripts.com/6397426.js';

head.appendChild(script)

var _hsq = window._hsq = window._hsq || [];

window.trackPageView = ()=>{
    _hsq.push(['trackPageView']);
}
