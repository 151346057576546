window.dataLayer = window.dataLayer || [];
function gtag(){
    dataLayer.push(arguments);
}
gtag('js', new Date());
gtag('config', 'UA-22074537-2');
gtag('config', 'G-60DH77PKQ7');

window.gtag = gtag;

var script = document.createElement('script');
script.type = 'text/javascript';
script.id = 'cw-gtag-loader';
script.defer = true;
script.async = true;
script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-22074537-2';
var head = document.getElementsByTagName('head')[0];
head.appendChild(script);
